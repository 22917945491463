

.close
  display none
  @media medium
    display: flex
    justify-content: flex-end
  @media small
    display: flex
    justify-content: flex-end

.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 100%
    transition: height 0.25s ease;
    margin-top 1rem
    @media medium
      margin-top 1rem
      width 100%
      padding 0 1rem
    @media small
      margin-top 1rem
      width 100%
      padding 0 1rem

.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.pre-register-form
  width: 100%
  margin-top 0
  background: white
  padding: 0
  &:not(.is-not-fixed)
    @media small
        width: 100%
        background: white
        position fixed
        bottom: 0
        left 0
        z-index: 100
        padding: 2rem 1rem
        box-shadow: 1px 1px 6px #00000029;
        border-radius: 4px
    @media medium
        width: 100%
        background: white
        position fixed
        bottom: 0
        left 0
        z-index: 100
        padding: 2rem 1rem
        box-shadow: 1px 1px 6px #00000029;
        border-radius: 4px

.action-submit
  position relative
  @media small
      position fixed
      bottom 20px
      width 100%
      margin-top -32px
      margin-left: -32px
      padding: 0 2rem
  @media medium
      position fixed
      bottom 20px
      width 100%
      margin-top -32px
      margin-left: -32px
      padding: 0 2rem

.btn-register
  width 100%
  padding 0 1rem
  display: none
  @media medium
    display: block
  @media small
    display: block

.form-layout:not(.is-not-fixed)
  height auto
  width 100%
  transition: max-height 0.15s ease;
  padding-top: 4px
  @media medium
    padding-top 0
    height 0px
    &--show
      height 520px
      transition: height 0.25s ease;
      overflow-y: auto
      overflow-x: hidden
  @media small
    height 0px
    padding-top 0
    &--show
      height 520px
      transition: height 0.25s ease;
      overflow-y: auto
      overflow-x: hidden

.policy-condition-layout
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start

.text-label-term
  color: $color-bnn
  cursor: pointer

.--error
  color: $color-bnn-red

add-to-calendar-button#add-to-calendar-button::part(atcb-button){
	background-color: #fff
	box-shadow: none
	font-size: 0.875rem
	margin: 0
	border: 2px solid $color-bnn
    color: $color-bnn
    padding: 14px 30px

	// v-btn
    font-family: 'SF Pro Display', 'SF Pro Text', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	align-items: center;
	border-radius: 32px;
	display: inline-flex;
	flex: 0 0 auto;
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	// height: 50px;
    // width: 48px;

	transition: 0.2s ease-in-out;

	&:hover {
        background-color: white;
		transition: 0.2s ease-in-out;
	}

    @media screen and (max-width: 1023px) {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

}

.form-customer-actions {
  display: flex
  align-items: center
  gap: 16px
  flex-direction: column
  > div {
    width: 100%
    flex-basis: 100%
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row

    > div {
        width: fit-content
        flex: 0 0 auto
    }
  }
  .btn-text {
    color: $color-bnn
    cursor: pointer
    font-size: 0.875rem
    font-weight: 500
	letter-spacing: 0.0892857143em
    text-transform: uppercase
    text-align: center
    order: 3
    @media screen and (min-width: 1024px) {
      order: unset
      margin-left: auto
    }
  }
}
